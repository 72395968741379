.wrapper_nav{
    position: fixed;
    top: 0;
    z-index: 980;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    transition: all .3s ease-in-out;
}
.wrapper_nav nav{
    max-width: 1320px;
    height: 60px;
}
.wrapper_nav .navbar-brand img{
    height: 60px;
}
.nav-link{
    font-weight: 600;
    color: var(--color-secundario);
}
.nav-link:hover,
.nav-link:focus{
    color: var(--color-principal);
    text-decoration: underline;
}
.activado{
    color: var(--color-principal) !important;
    text-decoration: underline;
}
.bg_nav{
    border-bottom: 1px solid var(--color-texto);
    background-color: var(--color-fondo);
}
.bg_nav_a{
    color: var(--color-texto);
}
.navbar-toggler{
    color: var(--color-principal);
    border: 2px solid var(--color-principal);
}
.navbar-toggler:focus{
    box-shadow: none;
}



@media (max-width:768px ){
    .navbar-nav{
        background-color: var(--color-fondo);
    }
}
