*{
  --color-logo: #65C400;
  --color-principal: rgb(0, 151, 33);
  --color-fondo-carrusel: rgb(8, 89, 24);
  --color-secundario: #DDDEDC;
  --color-principalGlass: #65c4005b;
  --color-secundarioGlass: #dddedccc;
  --color-texto: #383838;
  --color-textoGlass: #383838a9;
  --color-border: #a5a7a8;
  --color-placeholder: #b4b4b4;
  --color-fondo: rgb(248, 249, 250);
  --color-parrafos: #515e72;
  --color-parrafos2: #3e4755;
  --color-footer: #1f1f1f;
  --color-error: #f73b3b;
  --color-green: #247a38;

  --step-0: clamp(0.62rem,  0.31rem   +  0.61vw,  0.75rem);
  --step-1: clamp(0.68rem,  0.36rem   +  0.61vw,  0.81rem);
  --step-2: clamp(0.81rem,  0.42rem   +  0.80vw,  1rem);
  --step-3: clamp(0.87rem,  0.4375rem +  0.80vw,  1.12rem);
  --step-4: clamp(0.93rem,  0.4375rem +  0.85vw,  1.25rem);
  --step-5: clamp(1.05rem,     0.50rem   +  1.15vw,  2.1rem);
  --step-6: clamp(1.18rem, 0.875rem + 1.5vw, 2.5rem);
  --step-7: clamp(1.5rem, 0.84rem + 2vw, 2.81rem);
  --step-8: clamp(1.875rem, 1.9rem + 2.8vw, 3.75rem);
  --step-9: clamp(2.375rem, 1.163rem + 4.7vw, 5rem);
}
.step-0{
font-size: var(--step-0);
}
.step-1{
font-size: var(--step-1);
}
.step-2{
font-size: var(--step-2);
}
.step-3{
font-size: var(--step-3);
}
.step-4{
font-size: var(--step-4);
}
.step-5{
font-size: var(--step-5);
}
.step-6{
font-size: var(--step-6);
}
.step-7{
font-size: var(--step-7);
}
.step-8{
font-size: var(--step-8);
}
.step-9{
font-size: var(--step-9);
}
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}
li {
  list-style: none;
}
a{
  text-decoration: none;
}
h1,h2,h3,h4,h5,h6{
  margin-top: 0;
}
p{
  margin: 0;
}
ul{
  padding: 0;
}
