.wrapper_estadisticas{
    min-height: 211px;
    background-color: var(--color-principal);
    color: white;
}
.wrapper_estadisticas > div{
    height: 98%;
    width: clamp(300px, 98%, 1320px);
}
.wrapper_estadisticas .card_estadisticas p {
    font-weight: 600;
}
