.wrapper_clientes{
    padding: 2rem 0;
    background-color: var(--color-fondo);
}
.clientes_texto h2{
    font-weight: 600;
    text-transform: uppercase;
}
.clientes_logos{
    width: clamp(300px, 80%, 1380px);
}
.clientes_logos img{
    width: 14%;
}
.clientes_logos .img_1{
    width: 18%;
}
.clientes_logos .img_2{
    width: 11%;
}
.clientes_logos .img_3{
    width: 10%;
}
.clientes_logos .img_4{
    width: 15%;
}
.clientes_logos .img_5{
    width: 8%;
}