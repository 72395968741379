.wrapper_queHacemos{
    position: relative;
    padding: 4.5rem 0;
    background: linear-gradient(to left, rgba(216, 215, 215, 0.93), rgba(250, 255, 251, 0.62)),
                url(../../../../../public/images/planos.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}
.main_queHacemos{
    width: clamp(250px, 98%, 1300px);
}
.main_queHacemos h2{
    width: 100%;
    font-weight: 600;
}
.main_queHacemos_texto div:first-child  {
    width: clamp(250px, 98%, 500px);
}
.main_queHacemos_texto p{
    font-weight: 500;
    color: var(--color-parrafos2);
    text-align: justify;
    line-height: 1.8;
}
.main_queHacemos_img img{
    width: 350px;
    height: 350px;
}
.btn_contactar{
    width: 240px;
    color: antiquewhite;
    font-weight: 500;
    background-color: var(--color-principal);
    transition: all .3s ease;
}
.btn_contactar:hover{
    color: antiquewhite;
    background-color: var(--color-green);
}



@media (min-width:992px ) {
    .main_queHacemos_texto p{
        width: clamp(300px, 98%, 530px);
        text-align: start;
    }
    .btn_contactar{
        width: 160px;
    }
}