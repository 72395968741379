.btn_modal{
    position: relative;
    left: auto;
    border: none;
    background: transparent;
    transition: all .4s ease-out !important;
}
.btn_modal h3{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;
    margin: auto;
    display: none;
    color: var(--color-fondo);
}
.btn_modal P{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 500;
    margin: auto;
    display: none;
    color: whitesmoke;
    font-weight: 500;
    font-size: .9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.btn_modal P span{
    color: var(--color-logo);
    font-size: .8rem;
}
.btn_modal:hover h3,
.btn_modal:hover p{
    display: block;
}
.btn_modal:hover{
    background:  rgba(19, 18, 18, 0.829);
}
.btn_modal img{
    position: relative;
    z-index: -100;
}


.wrapper_images_modal,
.wrapper_textos_modal{
    width: 100%;
}
.modal_proyectos .modal-header{
    border-bottom: 0;
}
.textos_modal{
    font-size: .9rem;
}
.titulo_modal{
    font-size: 1.65rem;
    color: var(--color-green);
}

@media (min-width:992px ) {
    .wrapper_images_modal{
        width: 54%;
    }
    .wrapper_textos_modal{
        width: 46%;
    }
}