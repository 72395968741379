.btn-whatsapp {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 999;
    border: 2px solid var(--color-logo);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    text-align: center;
    background-color: var(--color-fondo); 
}
.whatsapp {
    font-size: 2.8rem;
    color: var(--color-logo); 
}
.btn-whatsapp .caja{
    position: fixed;
    bottom: 70px;
    right: 20px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: red;
}
.btn-whatsapp .caja span{
    color: var(--color-fondo);
    font-size: .8rem;
    font-weight: 600;
}