.container-servicios-enlaces{
	transition: all 0.3s ease;
}
.container-servicios-enlaces > button{
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
	color: var(--color-texto);
    font-weight: 600;
	text-transform: uppercase;
	text-align: center;
}
.container-servicios-enlaces button:hover,
.container-servicios-enlaces button:hover > * {
    color: var(--color-principal);
}
.icon_activo,
.icon_activo > i {
	color: var(--color-principal) !important;
}
.fa-paste, .fa-building, .fa-faucet, .fa-tools, .fa-bacon, .fa-warehouse{
	margin-right: .8rem;
	color: var(--color-texto);
	transform: scaleX(1.1);
}
.wrapper_carrusel_servicios .carousel-item{
    transition: all .3s ease;
}
.wrapper_carrusel_servicios .carousel-item img{
    width: 100%;
}
.wrapper_carrusel_servicios .carousel-item .caption{
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: var(--color-fondo);
    background-color: var(--color-fondo-carrusel);
}
.wrapper_carrusel_servicios .carousel-item .caption .active{
    text-decoration: none !important;
}
.wrapper_carrusel_servicios .carousel-item .caption h3{
    font-weight: 600;
    text-transform: uppercase;
}
.wrapper_carrusel_servicios .carousel-item .caption p{
    max-width: 90%;
}



/* Carrusel Modal de los Proyectos */
.wrapper_carrusel_modal .carrusel-modal-btns button{
    border: none;
}

.wrapper_carrusel_modal .carrusel-modal-btns img{
    width: 60%;
    border-radius: 3px;
}
.enlace-servicios{
    background-color: transparent;
}
.texto-servicios li{
    list-style-type: disc;
}



@media (min-width:768px ) {
    .wrapper_carrusel_servicios .carousel-item img{
        width: 50%;
    }
    .wrapper_carrusel_servicios .carousel-item .caption{
        min-height: 100%;
    }
    .wrapper_carrusel_servicios .carousel-item .caption p{
        max-width: 80%;
    }
}