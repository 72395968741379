.wrapper_nuestros_servicios{
    max-height: contain;
    background-color: var(--color-fondo);
}
.nuestros_servicios_texto h2{
    font-weight: 600;
    text-transform: uppercase;
}
.nuestros_servicios_texto p{
    width: clamp(300px, 90%, 1320px);
    color: var(--color-parrafos);
    font-weight: 500;
    line-height: 1.8;
}

