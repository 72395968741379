footer{
    color: var(--color-fondo);
    background-color: var(--color-footer);
}
.footer_pie{
    width: clamp(300px, 80%, 1320px);
}
.bi-linkedin{
    color: var(--color-principal);
}
.pie_copyright p{
    font-weight: 400;
    color: var(--color-placeholder);
    word-spacing: 5px;
}
.autor{
    color: var(--color-placeholder);
}
.autor a{
    color: var(--color-principal);
    font-weight: 500;
}