.wrapper_trabajos{
    padding: 3rem 0;
}
.wrapper_trabajos .trabajos_texto{
    width: clamp(300px, 98%, 1320px);
}
.wrapper_trabajos .trabajos_texto h2{
    font-weight: 600;
    text-transform: uppercase;
}
.wrapper_trabajos .trabajos_texto p{
    width: clamp(300px, 90%, 1320px);
    color: var(--color-parrafos);
    font-weight: 500;
    line-height: 1.8;
}
.wrapper_trabajos_card{
    min-height: 280px;
}
.trabajos_card:nth-child(1),
.trabajos_card:nth-child(2),
.trabajos_card:nth-child(3){
    margin-bottom: 6rem;
}
.trabajos_card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.indicadores{
    color: var(--color-green);
}
.text_justify{
    text-align: justify;
}
@media (min-width:992px ) {
    .trabajos_card:nth-child(1),
    .trabajos_card:nth-child(2),
    .trabajos_card:nth-child(3){
        margin-bottom: 0;
    }
}
