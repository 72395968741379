.odometro{
    font-size: 3.5rem;
}

@media (min-width:576px ) {
  .odometro{
    font-size: 4.5rem;
  }
}
@media (min-width:992px ) {
  .odometro{
    font-size: 5.5rem;
  }
}