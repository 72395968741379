.porque_nosotros_texto h2{
    font-weight: 600;
    text-transform: uppercase;
}
.porque_nosotros_texto p{
    width: clamp(300px, 80%, 1320px);
    color: var(--color-parrafos);
    font-weight: 500;
    line-height: 1.8;
}
.wrapper_porque_nosotros .container{
    max-width: 1380px;
}