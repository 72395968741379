.container_contact{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs'  preserveAspectRatio='none' viewBox='0 0 1440 760'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1001%26quot%3b)' fill='none'%3e%3crect width='1440' height='760' x='0' y='0' fill='rgba(241%2c 243%2c 241%2c 1)'%3e%3c/rect%3e%3cpath d='M1440 0L1438.7 0L1440 136.51z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1438.7 0L1440 136.51L1440 350.17999999999995L781.0300000000001 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M781.0300000000001 0L1440 350.17999999999995L1440 564.8799999999999L750.2600000000001 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M750.2600000000001 0L1440 564.8799999999999L1440 596.5999999999999L508.2400000000001 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 760L16.51 760L0 435.11z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 435.11L16.51 760L759.63 760L0 235.52z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 235.51999999999998L759.63 760L1159.3600000000001 760L0 179.30999999999997z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 179.30999999999995L1159.3600000000001 760L1347.6200000000001 760L0 80.23999999999995z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1001'%3e%3crect width='1440' height='760' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: cover;
}
.container_contacto{
    min-height: 100vh;
    background-color: var(--color-fondo);
}
.fondo_nav{
    height: 60px;
    background-color: var(--color-texto);
}
.wrapper_contacto{
    width: clamp(300px, 90%, 1320px);
}
.title_contacto {
    font-weight: 600;
    text-transform: uppercase;
}
.input{
    border: 2px solid  var(--color-border);
    background-color: transparent;
}
.input:focus, 
.input:visited, 
.input:active {
    outline: none;
    border-color: var(--color-principal);
    background-color: transparent;
    box-shadow: none;
}
.errorInput{
    border: 2px solid   var(--color-error);
    background-color: transparent;
}
.errorInput:focus, 
.errorInput:visited, 
.errorInput:active{
    outline: none;
    border-color:  var(--color-error);
    background-color: transparent;
    box-shadow: none;
}
.error{
    color: var(--color-error);
    font-size: .88rem;
}
.bloqueado{
    cursor: not-allowed!important;
}
.contacto_formulario,
.contacto_ubicacion{
    width: 90%;
}
.contacto_formulario .wrapper_form{
    width: 100%;
}
.contacto_formulario .btn-success{
    border-color: var(--color-texto);
    background-color: var(--color-texto);
    transition: all .3s ease-in;
}
.contacto_formulario .btn-success:hover{
    border-color: #1acf23;
    background-color: #1acf23;
}
.fa-envelope,
.fa-phone-alt,
.fa-globe,
.fa-map-marker-alt{
    color: var(--color-principal);
}
.icon-contacto{
    color: var(--color-principal);
}
.wrapper_mapa{
    height: 350px;
    box-shadow: 8px 8px 16px rgba(165, 177, 198, 0.8),
				-8px -8px 16px rgba(255, 255, 255, 0.8);
}
.contacto,
.direccion{
    width: 100%;
}
@media (min-width:576px ) {
    .contacto_formulario .wrapper_form{
        width: clamp( 350px, 70%, 475px);
    }
}

@media (min-width:992px ) {
    .contacto_formulario,
    .contacto_ubicacion{
        width: 50%;
    }
    .contacto_formulario .wrapper_form{
        width: 80%;
    }
}