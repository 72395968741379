.wrapper_header_home{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(37,37,37, .9), rgba(0,173,70, .2));
    
}

.wrapper_header_home .fondo_header_home,
.fondo_header_home img {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    min-width: 100%;
    z-index: -1;
}

.header_home{
    position:absolute;
    top: 61px;
    width: clamp(250px, 40vw, 1300px);
}
.header_home img{
    width: 236px;
    height: 236px;
}

@media (min-width:1200px ) {
    .wrapper_header_home .fondo_header_home{
        min-width: 100%;
        height: auto;
        width: 100%;
    }
    
}
